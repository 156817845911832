import React, { Component } from "react";
import "../Sig/Sig.scss";

export default class Sig extends Component {
  render() {
    return (
      <div className="footer">
        <p className="sig">© 2022, Henosis LLC</p>
      </div>
    );
  }
}
