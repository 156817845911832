import React from "react";
import { NavLink } from "react-router-dom";
import first from "./assets/images/created.png";
import mid from "./assets/images/in-dev.png";
import last from "./assets/images/assets.png";
import "./Home.scss";

function Home() {
  return (
    <div className="main">
      <div className="buttons">
        <NavLink to="/companies">
          <img src={first} className="first" alt="henosis-holdings" />
        </NavLink>
        <NavLink to="/in-development-projects">
          <img src={mid} className="mid" alt="henosis-holdings" />
        </NavLink>
        <NavLink to="/properties">
          <img src={last} className="last" alt="henosis-holdings" />
        </NavLink>
      </div>
    </div>
  );
}

export default Home;
