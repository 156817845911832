import React, { Component } from "react";
import "../Indev/Indev.scss";
import projectx from "../../assets/images/8th-street-app.png";

export default class Indev extends Component {
  render() {
    return (
      <div className="indev-page">
        <p className="indev-page__body">Current projects being developed</p>
        <div className="indev-page__built">
          <a href="javascript:;">
            <img
              src={projectx}
              className="indev-page__companies"
              alt="8th-street-app-coming-soon"
            />
          </a>
        </div>
      </div>
    );
  }
}
