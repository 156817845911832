import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Tm from "./components/Tm/Tm";
import Home from "./Home";
import Created from "./components/Created/Created";
import Indev from "./components/Indev/Indev";
import Netasset from "./components/Netasset/Netasset";
import Sig from "./components/Sig/Sig";

ReactDOM.render(
  <Router>
    <Tm />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/companies" element={<Created />} />
      <Route path="/in-development-projects" element={<Indev />} />
      <Route path="/properties" element={<Netasset />} />
    </Routes>
    <Sig />
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
