import React, { Component } from "react";
import "../Netasset/Netasset.scss";

export default class Netasset extends Component {
  render() {
    return (
      <div className="netasset-page">
        <p className="netasset-page__body">Portfolio coming soon!</p>
      </div>
    );
  }
}
