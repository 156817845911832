import React, { Component } from "react";
import "../Created/Created.scss";
import grx from "../../assets/images/gloss-rx-trademark.png";
import jbluery from "../../assets/images/jbluery-trademark.png";
import hh from "../../assets/images/henosis-hospitality-trademark.png";
import beacon from "../../assets/images/the-marine-beacon-trademark.png";
import lafd from "../../assets/images/lost-arts-forged-in-dade-trademark.png";
import rosio from "../../assets/images/rosio-trademark.png";
import gk from "../../assets/images/gildas-kids-trademark.png";

export default class Created extends Component {
  render() {
    return (
      <div className="created-page">
        <p className="created-page__body">
          Proudly built companies in Miami, Florida
        </p>
        <div className="created-page__built">
          <a href="https://www.instagram.com/gloss_rx/">
            <img
              src={grx}
              className="created-page__companies"
              alt="gloss-rx-trademark"
            />
          </a>
          <a href="https://www.pinksauce.io/">
            <img
              src={jbluery}
              className="created-page__companies"
              alt="jbluery-trademark"
            />
          </a>
          <a href="javascript:;">
            <img
              src={hh}
              className="created-page__companies"
              alt="henosis-hospitality-trademark"
            />
          </a>
          <a href="https://www.marinebeacon.io/">
            <img
              src={beacon}
              className="created-page__companies"
              alt="the-marine-beacon-trademark"
            />
          </a>
          <a href="javascript:;">
            <img
              src={lafd}
              className="created-page__companies"
              alt="lost-arts-forged-in-dade-trademark"
            />
          </a>
          <a href="javascript:;">
            <img
              src={rosio}
              className="created-page__companies"
              alt="rosio-trademark"
            />
          </a>
          <a href="https://www.gildaskids.com/">
            <img
              src={gk}
              className="created-page__companies"
              alt="gildas-kids-trademark"
            />
          </a>
        </div>
      </div>
    );
  }
}
