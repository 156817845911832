import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import holdings from "../../assets/images/henosis-holdings-registered-trademark.png";
import "../Tm/Tm.scss";

export default class Tm extends Component {
  render() {
    return (
      <div className="header">
        <NavLink to="/">
          <img src={holdings} className="holdings" alt="henosis-holdings" />
        </NavLink>
      </div>
    );
  }
}
